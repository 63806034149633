import React from 'react'
import PropTypes from 'prop-types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal,Form,Input,Space,Radio,Button,Switch,message } from 'antd'
const AddAppModal = ({open,close,api,fetch}) => {
    const {TextArea} = Input
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol: {
            xs:{
               span:3
            }
          },
        wrapperCol:{
            xs:{
              span:22
            }
        }
    }
    const onFinish = async (values) => {
        const {data:res} = await api(values)
        if(res.isSuccess){
            message.success(res.message)
            close()
            fetch()
        }
    };
    const resetForm = () =>{
        form.resetFields()
        close()
    }
    return (
        <Modal title="Add Brand" visible={open} onOk={form.submit} onCancel={resetForm} width={'60vw'} height={'65vw'}>
            <Form  form={form} {...formItemLayout} name="addQuestionForm" onFinish={onFinish} autoComplete="off">
                <Form.Item name="name" label="Name" rules={[{required:true,message:'Missing Name'}]}>
                    <Input placeholder="Brand Name" />
                </Form.Item>
                <Form.Item name="description" label="Description">
                    <TextArea rows={4} placeholder="Brand Description" />
                </Form.Item>
                <Form.Item name="status" initialValue={1} label="Status" rules={[{required:true,message:'Missing Status'}]}>
                    <Radio.Group>
                        <Radio value={0}>Inactive</Radio>
                        <Radio value={1}>Active</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
}

AddAppModal.propTypes = {

}

export default AddAppModal
