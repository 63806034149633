import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Player from 'components/common/Player';
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function CoursewareUpload(props) {
  const [files, setFiles] = useState([]);
  useEffect(()=>{
    if(props.isClear){
      setFiles([])
   }
  },[props.isClear])
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*,video/*',
    multiple:props.multiple,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
     props.getFile(acceptedFiles)
    }
  });


  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {file.type.indexOf('image')!==-1?(<img src={file.preview} style={img} alt={file.name} />):(<video src={file.preview}></video>) }
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag any images/videos here, or click here and select some images/videos by "Ctrl"</p>
      </div>
      <aside style={thumbsContainer}>
        {files.length>0?thumbs:''}
      </aside>
    </section>
  );
}

export default CoursewareUpload