import axios from 'axios'
import { message } from 'antd'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
let baseUrl = ''
/* switch (process.env.REACT_APP_ENV) {
    case "production":
        baseUrl = ''
        break;
    case "test":
        baseUrl = 'http://qc.tqstech.cn:7044/'
        break;
    case "development":
        baseUrl = "https://localhost:44300";
        break;
    default:
        baseUrl = "https://localhost:44300";
        break;
} */
const service = axios.create({
    baseURL: baseUrl,
    timeout: 100000 // request timeout
})
service.defaults.retry = 4
service.defaults.withCredentials = true
// 请求拦截器
service.interceptors.request.use(config => {
    nProgress.start()
    return config
}, error => {
    return Promise.reject(error)
})
// 响应拦截器
service.interceptors.response.use(response => {
    if(!response.data.isSuccess){
        message.error(response.data.message)
    }
    nProgress.done()
    return response
}, error => {
    message.error(`An unknown error occurred`)
    return Promise.reject(error)
})

export default service