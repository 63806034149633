import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Modal,Slider,message,Space } from 'antd'
import UploadCase from 'components/common/UploadCase'
let initData = {resultList:[
  {scoreLine:100,fileUrl:'123'}
]}
const Result = ({ id, open, close, api, fetch }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input
  const [files, setFiles] = useState({})
  const [loading, setLoading] = useState(false)
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 3
      }
    },
    wrapperCol: {
      xs: {
        span: 22
      }
    }
  }
  const onFinish = async (values) => {
    setLoading(true)
    const { data: res } = await api(values)
    if (res.isSuccess) {
      setLoading(false)
      message.success(res.message)
      resetForm()
      fetch()
    }
  };
  const resetForm = () => {
    form.resetFields()
    // setOptionData(initialValue)
    // setIsMultiple(true)
    close()
  }
  return (
    <Modal confirmLoading={loading} width={'50vw'} title="The display after the user answers the questions" visible={open} onOk={form.submit} onCancel={resetForm}>
      <Form form={form} {...formItemLayout}  name="result" initialValues={initData} onFinish={onFinish} autoComplete="off">
        <Form.List name="resultList">
          {(fields, { add, remove }) =>
            fields.map(({ key, name, fieldKey }) => (
            <Space align="baseline">
              <Form.Item label="score">
                    <Slider></Slider>
              </Form.Item>
              <Form.Item
                  label="displayImage"
                  name={[name, 'displayImage']}
                  fieldKey={[fieldKey,'displayImage']}
                  rules={[{ required: true, message: 'Missing Display Image' }]}
              >
                <UploadCase listType="picture" />
              </Form.Item>
            </Space>
            ))
          }
        </Form.List>
      </Form>
    </Modal>
  )
}

Result.propTypes = {

}

export default Result
