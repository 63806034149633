
import Index from "../pages/Paper";
import ErrorPage from "../pages/Error";
import Login from "../pages/SignIn";
import AppPaper from '../pages/AppPage'
import Question from '../pages/Question'
import Training from '../pages/Training';
import TrainingList from "../pages/TrainingList";
import Finish from "../pages/Finish";
import Share from "../pages/Share";
import User from "pages/User";

export default [
  { path: "/", name: "Home", component: Index, auth: true },
  { path: "/Paper", name: "Home", component: Index, auth: true },
  { path: "/Question", name: "Question", component: Question, auth: true },
  { path: "/User", name: "User", component: User, auth: true },
  { path: "/App", name: "App", component: AppPaper, auth: true },
  { path: "/Training", name: "Training", component: Training },
  { path: "/training", name: "Training", component: Training },
  { path: "/TrainingList", name: "TrainingList", component: TrainingList },
  { path: "/traininglist", name: "TrainingList", component: TrainingList },
  { path: "/login", name: "Login", component: Login },
  { path: "/404", name: "404", component: ErrorPage },
  { path: "/finish",name:"Finish",component:Finish},
  { path: "/s",name:"Share",component:Share},

]