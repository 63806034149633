import React, { Component } from 'react';

export default class Footer extends Component {

    render() {
        return (
            <footer className="footer">
                    <nav>
                        <ul>
                        </ul>
                    </nav>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, Designed by Eurofase.
                    </div>
            </footer>
        );
    }
}
