import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Space, InputNumber, Radio, Button, Switch, message } from 'antd'
const AddPaperModal = ({ open, close, api, fetch }) => {
    const { TextArea } = Input
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [a, setA] = useState(100)
    const [b, setB] = useState(0)
    const [c, setC] = useState(0)
    const [d, setD] = useState(0)
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 4
            }
        },
        wrapperCol: {
            xs: {
                span: 21
            }
        }
    }
    const onChange = (name) => {
        switch (name) {
            case 'a':
                return (e) => {
                    setA(e||100)
                }
            case 'b':
                return (e) => {
                    setB(e||0)
                }
            case 'c':
                return (e) => {
                    setC(e||0)
                }
            case 'd':
                return (e) => {
                    setD(e||0)
                }
            default:
                return
        }
    }
    const onFinish = async (values) => {
        setLoading(true)
        const { data: res } = await api(values)
        if (res.isSuccess) {
            setLoading(false)
            message.success(res.message)
            resetForm();
            fetch()
        }
    };
    const resetForm = () => {
        form.resetFields()
        close()
    }
    return (
        <Modal confirmLoading={loading} width={'50vw'} title="Add Quiz" visible={open} onOk={form.submit} onCancel={resetForm}>
            <Form form={form} {...formItemLayout} name="addQuestionForm" onFinish={onFinish} autoComplete="off">
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Missing Name' }]}>
                    <Input placeholder="Quiz Name" />
                </Form.Item>
                <Form.Item name="description" label="Description">
                    <TextArea rows={4} placeholder="Quiz Description" />
                </Form.Item>
                <Form.Item name="status" initialValue={'Active'} label="Status" rules={[{ required: true, message: 'Missing Status' }]}>
                    <Radio.Group>
                        <Radio value={'Inactive'}>Inactive</Radio>
                        <Radio value={'Active'}>Active</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name='levelAScore' label="Level A Score">
                    <InputNumber placeholder='score' max={100} value={a} onChange={onChange('a')} />
                </Form.Item>
                <Form.Item name='levelAMessage' label="Level A Evaluate">
                    <Input placeholder='Level A Evaluate' />
                </Form.Item>
                <Form.Item name='levelBScore' label="Level B Score">
                    <InputNumber placeholder='score' max={a - 1} value={b} onChange={onChange('b')} />
                </Form.Item>
                <Form.Item name='levelBMessage' label="Level B Evaluate">
                    <Input placeholder='Level B Evaluate' />
                </Form.Item>
                <Form.Item name='levelCScore' label="Level C Score">
                    <InputNumber placeholder='score' max={b - 1} value={c} onChange={onChange('c')} />
                </Form.Item>
                <Form.Item name='levelCMessage' label="Level C Evaluate">
                    <Input placeholder='Level C Evaluate' />
                </Form.Item>
                <Form.Item name='levelDScore' label="Level D Score">
                    <InputNumber placeholder='score' max={c - 1} value={d} onChange={onChange('d')} />
                </Form.Item>
                <Form.Item name='levelDMessage' label="Level D Evaluate">
                    <Input placeholder='Level D Evaluate' />
                </Form.Item>
            </Form>
        </Modal>
    )
}

AddPaperModal.propTypes = {

}

export default AddPaperModal
