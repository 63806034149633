import request from '../utils/request'
import { sessionGet } from 'utils/session'
const BASE_URL = '/api/Question'

export function GetQuestionList(keyword,skip=0,take=20){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetList`,
        method:'get',
        params:{
            keyword,
            skip,
            take
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetQuestion(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Get`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetQuestionInfo(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetQuestionInfo`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetQuestionFile(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetQuestionFile`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function SaveQuestion(DataString,files,coursewarelist){
    const token = sessionGet('token')
    let formData = new FormData()
    if(coursewarelist){
        coursewarelist.forEach((item)=>{
            formData.append('coursewarelist',item)
        })
    }

    formData.append('files',files)
    formData.append('DataString',JSON.stringify (DataString))
    return request({
        url:`${BASE_URL}/Save`,
        method:'post',
        data:formData,
        headers: {  Authorization: `${token}`  }
    })
}
export function DeleteQuestion(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Delete`,
        method:'post',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function SaveTag(id,name){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/SaveTag`,
        method:'post',
        params:{
            id,
            name
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function removeTag(id,name){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/RemoveTag`,
        method:'post',
        params:{
            id,
            name
        },
        headers: {  Authorization: `${token}`  }
    })
}

