import React, { useState,useEffect } from 'react'
import PropTypes from 'prop-types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Space, Radio, Button, Switch, message, Col, Row } from 'antd'
import UploadCase from 'components/common/UploadCase';
const initialValue = [
  { optionName: "A", isAnswer: true, optionValue: "" },
  { optionName: "B", isAnswer: false, optionValue: "" }
];
const AddQuestionModal = ({ open, close, answerLetter, api, fetch }) => {

  const [optionData,setOptionData] = useState(initialValue)
  const [isMultiple, setIsMultiple] = useState(true)
  const [files, setFiles] = useState({})
  const {TextArea} = Input
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 3
      }
    },
    wrapperCol: {
      xs: {
        span: 22
      }
    }
  }
  const singleSync = (func,name='') =>{
    setOptionData(form.getFieldValue('optionList'))
    return () =>{
      func(name)
    }
}
  const singleChange = (e) =>{
    let selectedIndex = e.target.value
    let data = [...optionData].map((item,index)=>{
        if(index===selectedIndex){
          item.isAnswer = true
          return item
        }else{
          item.isAnswer =false
          return item
        }
    })
    setOptionData(data)
  }
  const handleTypeChange = (e) => {
    if (e.target.value === 0) {
      setIsMultiple(true)
    } else if (e.target.value === 1) {
      setIsMultiple(false)
    }
  }
  const formAddFile = files => {
    setFiles(files)
  }
  const onFinish = async (values) => {
    setLoading(true)
    const { data: res } = await api(values, files)
    if (res.isSuccess) {
      setLoading(false)
      message.success(res.message)
      resetForm()
      fetch()
    }else{
      setLoading(false)
    }

  };
  const resetForm = () => {
    form.resetFields()
    setOptionData(initialValue)
    setIsMultiple(true)
    close()
  }
const multiple = (<Form.List name="optionList" initialValue={optionData}>
  {(fields, { add, remove }) => (
    <>
      {fields.map(field => (
        <div className="question-option" key={answerLetter[field.name]} align="baseline">
          <div className="option-space">
            <Form.Item
              name={[field.name, 'optionName']}
              fieldKey={[field.fieldKey, 'optionName']}
              hidden={true}
              initialValue={answerLetter[field.name]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={[field.name, 'isAnswer']}
              fieldKey={[field.fieldKey, 'isAnswer']}
              rules={[{ required: true, message: 'Missing Choose' }]}
              initialValue={false}
            >
              <Radio.Group>
                <Radio.Button value={true}>Right</Radio.Button>
                <Radio.Button value={false}>Wrong</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="option-space flex-1">
            <Form.Item
              style={{ flex: 1 }}
              name={[field.name, 'optionValue']}
              fieldKey={[field.fieldKey, 'optionValue']}
              rules={[{ required: true, message: 'Option Value' }]}
            >
              <Input placeholder={`Option ${answerLetter[field.name]}`} />
            </Form.Item>
          </div>
          <div className="option-space">
            <Button block icon={<MinusCircleOutlined />} onClick={() => remove(field.name)} danger>Remove</Button>
          </div>
        </div>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
          Add Answer
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>)
const single = (<Form.List name="optionList" initialValue={optionData}>
  {(fields, { add, remove }) => (
    <>
      {fields.map(field => (
        <div className="question-option" key={answerLetter[field.name]} align="baseline">
          <div className="option-space">
            <Form.Item
              name={[field.name, 'optionName']}
              fieldKey={[field.fieldKey, 'optionName']}
              hidden={true}
              initialValue={answerLetter[field.name]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={[field.name, 'isAnswer']}
              fieldKey={[field.fieldKey, 'isAnswer']}
              hidden={true}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="option-space flex-1">
            <Form.Item
              style={{ flex: 1 }}
              name={[field.name, 'optionValue']}
              fieldKey={[field.fieldKey, 'optionValue']}
              rules={[{ required: true, message: 'Option Value' }]}
            >
              <Input placeholder={`Option ${answerLetter[field.name]}`} />
            </Form.Item>
          </div>
          <div className="option-space">
            <Button block icon={<MinusCircleOutlined />} onClick={singleSync(remove,field.name)} danger>Remove</Button>
          </div>
        </div>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={singleSync(add)} block icon={<PlusOutlined />}>
          Add Answer
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>)
const choice = (<Form.Item label="Choice"  rules={[{ required: true, message: 'Missing Choice' }]}>
  <Radio.Group onChange={singleChange} defaultValue={optionData.findIndex(item=>item.isAnswer===true)}>
    {
      optionData.map((item, index) => (
        <Radio.Button key={index} value={index}>{answerLetter[index]}</Radio.Button>
      ))
    }
  </Radio.Group>
</Form.Item>)
  return (
    <Modal confirmLoading={loading} width={'50vw'} title="Add Question" visible={open} onOk={form.submit} onCancel={resetForm}>
      <Form form={form} {...formItemLayout} name="addQuestionForm" onFinish={onFinish} autoComplete="off">
        <Form.Item name="topic" label="Topic" rules={[{ required: true, message: 'Missing Topic' }]}>
          <TextArea rows={4} placeholder="Question Topic" />
        </Form.Item>
        <Form.Item label="Tips" name="tips" rules={[{ required: true, message: 'Missing Tips' }]}>
          <Input placeholder="The prompt you want to give the user if the user answers incorrectly" />
        </Form.Item>
        <Form.Item label="Image/Video" >
          <UploadCase open={open} listType="picture" getFile={formAddFile} />
        </Form.Item>
        <Form.Item name="status" initialValue={1} label="Status" rules={[{ required: true, message: 'Missing Status' }]}>
          <Radio.Group>
            <Radio.Button value={0}>Inactive</Radio.Button>
            <Radio.Button value={1}>Active</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Type" initialValue={0} name="optionType" >
          <Radio.Group onChange={handleTypeChange}>
            <Radio.Button value={0}>Multiple choice</Radio.Button>
            <Radio.Button value={1}>Single choice</Radio.Button>
          </Radio.Group>
        </Form.Item>
          {!isMultiple?choice:''}
        <Form.Item label="Option">
          {isMultiple ? multiple : single}
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddQuestionModal.propTypes = {

}

export default AddQuestionModal
