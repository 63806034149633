import React, { Component } from 'react'
import { Result, Button } from 'antd'

import { SmileOutlined, MehOutlined,FrownOutlined } from '@ant-design/icons'
import qs from 'querystring'
export default class Finish extends Component {
  pushToList = () => {
    const { key } = this.props.location.state
    this.props.history.push(`/traininglist?key=${key}`)
  }
  render() {
    const { message, level, score } = this.props.location.state
    return (
      <Result
        icon={level === 'A' ? <SmileOutlined /> : level === 'B' ? <MehOutlined /> :level === 'C' ? <MehOutlined />:<FrownOutlined />}
        title={`Your grade is ${level},Your score is ${score}`}
        subTitle={message||'You still have to work hard'}
        extra={[
          <Button type="primary" key="console" onClick={this.pushToList}>
            Return TrainingList
          </Button>,
        ]}
        className="finish-result"
        style={{paddingTop: '300px'}}
      />
    )
  }
}
