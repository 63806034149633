import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MyLayout from './Layout'
import Footer from '../components/layout/Footer';
import { GetQuestionList, SaveQuestion, GetQuestion, DeleteQuestion } from '../api/question';
import CoursewareModal from 'components/paper/CoursewareModal';
import { EditOutlined, ReconciliationOutlined, CloseOutlined, PlusOutlined, ExclamationCircleOutlined,FileImageOutlined } from '@ant-design/icons';
import { Input, Table, Divider, Space, Button, message, Modal } from 'antd';
import AddQuestionModal from 'components/question/AddQuestionModal';
import EditQuestionModal from 'components/question/EditQuestionModal'
import Tags from 'components/question/Tags';
import { withRouter, Link } from 'react-router-dom';
import logo from '../assets/img/login-logo-blue.png'
const { confirm } = Modal
export class Question extends Component {
    state = {
        data: [],
        id: '',
        total: 0,
        loading: false,
        keyword: '',
        collapsed: false,
        initialValue: {},
        addModalShow: false,
        editModalShow: false,
        coursewareModalShow: false,
        answerLetter: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    }
    fetchData = async (keyword = '', skip = 0, take = 10) => {
        this.setState({ loading: true })
        const { data: res } = await GetQuestionList(keyword, skip, take)
        if (res.isSuccess) {
            this.setState({ data: res.data, loading: false, total: res.totalCount })
        }
    }
    addModalOpen = () => {
        this.setState({ addModalShow: true })
    }
    editModalOpen = (id) => {
        return async () => {
            const { data: res } = await GetQuestion(id)
            if (res.isSuccess) {
                this.setState({ id, initialValue: res.data, editModalShow: true })
            }
        }
    }
    deleteQuestion = async (id) => {
        const { data: res } = await DeleteQuestion(id)
        if (res.isSuccess) {
            message.success(res.message)
            this.fetchData()
        }
    }
    deleteModalOpen = (id) => {
        let that = this
        return () => {
            confirm({
                title: 'Do you Want to delete these items?',
                icon: <ExclamationCircleOutlined />,
                // content: 'Some descriptions',
                onOk() {
                    return that.deleteQuestion(id)
                },
                onCancel() {
                    //   console.log('Cancel');
                },
            });
        }
    }
    onSearch = (val) => {
        this.setState({ keyword: val })
        this.fetchData(val)
    }
    modalClose = () => {
        this.setState({
            addModalShow: false,
            editModalShow: false,
            coursewareModalShow: false,
            initialValue: {}
        })
    }
    paginationChange = (current, pageSize) => {
        const { keyword } = this.state
        this.fetchData(keyword, (current - 1) * pageSize, pageSize)
    }
    coursewareModalOpen = (id) => {
        return () => {
            this.setState({
                id,
                coursewareModalShow: true,
            })
        }
    }
    componentDidMount = () => {
        this.fetchData()
    }
    componentDidUpdate = () => {

    }
    columns = [
        {
            title: 'Topic',
            dataIndex: 'topic',
            render: (text, record) => {
                return (
                    <Space direction="vertical">
                        <Tags id={record.id} fetch={this.fetchData} tags={record.tags || []} />
                        <p> {record.topic}</p>
                    </Space>
                )

            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                switch (record.status) {
                    case 0:
                        return 'Inactive'
                    case 1:
                        return 'Active'
                    default:
                        return 'Inactive'
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="default"
                        onClick={this.coursewareModalOpen(record.id)}
                        icon={<FileImageOutlined />}
                    >
                        Courseware
                    </Button>
                    <Button type="primary" shape="round" onClick={this.editModalOpen(record.id)} icon={<EditOutlined />}>Edit</Button>
                    {
                        record.candDel ? <Button type="danger" shape="round" onClick={this.deleteModalOpen(record.id)} icon={<CloseOutlined />}>Delete</Button> : ''
                    }
                </Space>
            )
        },
    ];

    render() {
        const { coursewareModalShow, id, loading, addModalShow, answerLetter, editModalShow, initialValue, total } = this.state
        return (
            <MyLayout>
                <div className="table-top">
                    <div style={{ alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Input.Search
                            size="large"
                            placeholder="Search Question"
                            style={{ width: '300px' }}
                            onSearch={this.onSearch}
                            enterButton />
                        <Button type="primary"
                            style={{ height: '40px' }}
                            onClick={this.addModalOpen}
                            icon={<PlusOutlined />}>Add Question</Button>
                    </div>
                    <CoursewareModal
                        id={id}
                        open={coursewareModalShow}
                        fetch={this.fetchData}
                        close={this.modalClose} />
                    <AddQuestionModal
                        fetch={this.fetchData}
                        answerLetter={answerLetter}
                        open={addModalShow}
                        api={SaveQuestion}
                        close={this.modalClose} />
                    <EditQuestionModal
                        id={this.state.id}
                        fetch={this.fetchData}
                        initialValue={initialValue}
                        answerLetter={answerLetter}
                        open={editModalShow}
                        api={SaveQuestion}
                        close={this.modalClose} />
                </div>
                <Divider />
                <Table
                    className='min-layout-height'
                    loading={loading}
                    rowKey={record => record.id}
                    pagination={{
                        total,
                        pageSizeOptions: [10, 20, 30, 40, 50],
                        onChange: this.paginationChange
                    }}
                    columns={this.columns}
                    dataSource={this.state.data}
                />
            </MyLayout>
        )
    }
}

export default Question
