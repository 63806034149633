import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MyLayout from './Layout'
import dayjs from 'dayjs'
import { GetHistoryList, GetEmailList } from '../api/score'
import { GetAccount } from '../api/account'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Input, Button, Divider, Table, Space } from 'antd'
export default class User extends Component {
    static propTypes = {

    }
    state = {
        data: [],
        childData:[],
        total: 0,
        keyword: '',
        loading: false,
    }
    columns = [
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Test Count', dataIndex: 'testCount', key: 'testCount' },
    ];
    fetchData = async (keyword = '', skip = 0, take = 20) => {
        this.setState({
            loading: true,
        });
        const { data: res } = await GetEmailList(keyword, skip, take);
        if (res.isSuccess) {
            this.setState({
                data: res.data,
                loading: false,
                total: res.totalCount
            });
        }
    };
    expandedRowRender = (record, index, indent, expanded) => {
        const { childData } = this.state
        const currentIndex = childData.findIndex(value => value.email === record.email)
        const columns = [
            { title: 'Quiz Name', dataIndex: 'name', key: 'name' },
            { title: 'Brand', dataIndex: 'app', key: 'app' },
            { title: 'Score', dataIndex: 'score', key: 'score' },
            { title: 'Time for the last exam', dataIndex: 'dateCreated', key: 'dateCreated',render:(text,record)=>{
                return dayjs(record.dateCreated).format('MM-DD-YYYY HH:mm:ss');
            } }
        ]
        if (currentIndex>=0) {
            return <Table
                rowKey={record => `${Math.floor(Math.random() * Date.now()) + 1}`}
                columns={columns}
                dataSource={childData[currentIndex]['data']}
                pagination={false} />
        }

    }
    onSearch = (val) => {
        this.setState({ keyword: val })
        this.fetchData(val)
    }
    paginationChange = (current, pageSize) => {
        const { keyword } = this.state
        this.fetchData(keyword, (current - 1) * pageSize, pageSize)
    }
    onExpand = async (expanded, record) => {
        const { email } = record
        const { data: res } = await GetHistoryList(email, 0, 100)
        if (res.isSuccess) {
            this.setState(state => {
                let childData = { ...state }.childData
                let index = childData.findIndex((value, index) => {
                        return value.email === email
                })
                if(index>=0){
                    childData[index] = {email,data:res.data}
                }else{
                    childData.push({email,data:res.data})
                }
                return { childData }
            })
        }
    }
    componentDidMount = () => {
        this.fetchData()
    }
    render() {
        const { loading, total } = this.state

        return (
            <MyLayout>
                <div className="table-top">
                    <div style={{ alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Input.Search
                            size="large"
                            placeholder="Search User"
                            style={{ width: '300px' }}
                            onSearch={this.onSearch}
                            enterButton />
                    </div>
                </div>
                <Divider />
                <Table
                    className='min-layout-height'
                    loading={loading}
                    rowKey={record => record.id}
                    onExpand={this.onExpand}
                    pagination={{
                        total,
                        pageSizeOptions: [10, 20, 30, 40, 50],
                        onChange: this.paginationChange
                    }}
                    columns={this.columns}
                    dataSource={this.state.data}
                    expandable={{
                        expandedRowRender: this.expandedRowRender
                    }}
                />
            </MyLayout>
        )
    }
}
