import React, { Component } from 'react'
import {
    Share,BeginExam
} from "api/training";
import {Col,Row,List,message,Button,Table,Space ,Input} from 'antd'
import logo from '../assets/img/login-logo-blue2.png'
class TrainingList extends Component {
    static propTypes = {

    }
    state = {
        valueMsg:'',
        code:''
    }
    componentDidMount = () =>{
        this.getShareCode()
    }

    getShareCode = async () =>{
        let code = this.props.location.search.substr(6)
        this.setState({
            code : code
        })
    }
    vluechange=(e)=>{
        let msgValue=e.target.value
        this.setState({
            valueMsg : msgValue
        })
    }
    testEmail = async () =>{
        const {valueMsg,code} = this.state
        let teg=/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
        if(teg.test(valueMsg)){
            const { data: res } = await Share(valueMsg,code);
            if(res){
                const { data: share } = await BeginExam(res.data.key,res.data.paperID);
                if(share.isSuccess){
                    message.success(share.message)
                    this.props.history.push('/training',{usid:share.data,key:res.data.key,paperName:res.data.paperName,paperID:res.data.paperID})
                }
            }
        }else{
            message.error('Mailbox format error')
        }
    }

    render() {
        const {valueMsg} = this.state
        return (
            <>
            <h1 className="logo-container">
                <img src={logo} alt="logo" />
            </h1>
            <Row>
                <Col span={14} offset={5}>
                    <Input.Group compact>
                    <Input style={{ width: 'calc(100% - 200px)' }} placeholder=" Please enter email address" value={valueMsg} onChange={this.vluechange} onPressEnter={this.testEmail}  />
                    <Button type="primary" onClick={this.testEmail}>OK</Button>
                </Input.Group>
                </Col>
            </Row>
            </>
        )
    }
}

export default TrainingList