import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { sessionGet } from "utils/session";
import PropTypes from "prop-types";
import Footer from "../components/layout/Footer";
import {
    DownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    BookOutlined,
    UserOutlined,
    FileTextOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    Layout,
    Menu,
    Dropdown,
    Modal,
} from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/img/login-logo-blue.png";
const { confirm } = Modal;
const { Header, Sider, Content } = Layout;
export class MyLayout extends Component {
    static propTypes = {};
    state = {
        collapsed: false,
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    select = ({ item, key, keyPath, domEvent }) => {
        console.log(key)
        this.props.history.push(key);
    };
    signOut = () => {
        let that = this;
        confirm({
            title: "Would you like to sign out?",
            icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                window.sessionStorage.removeItem("token");
                that.props.history.push("/login");
            },
            onCancel() {
            },
        });


    };
    render() {
        const { children } = this.props
        let accountHtml = "";
        let isLogin = sessionGet("token");
        let email = sessionGet("email");
        if (isLogin) {
            let dropdownMenu = (
                <Menu>
{/*                     <Menu.Item key="/">
                        Change Password
                    </Menu.Item> */}
                    <Menu.Item key="/login" onClick={this.signOut}>
                        Sign Out
                    </Menu.Item>
                </Menu>
            );
            accountHtml = (
                <Dropdown overlay={dropdownMenu}>
                    <a className="ant-dropdown-link">
                        {email} <DownOutlined />
                    </a>
                </Dropdown>
            );
        } else {
            accountHtml = <Link to="/signin"> Sign in </Link>;
        }
        return (
            <>
                <Layout className="min-height-80vh">
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                        <div className="logo">
                            <Link
                                to="/"
                                style={{
                                    display: "block",
                                }}
                            >
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <Menu
                            onSelect={this.select}
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={[this.props.location.pathname]}
                        >
                            <Menu.Item key="/" icon={<BookOutlined />}>
                                Quiz
                            </Menu.Item>
                            <Menu.Item key="/Question" icon={<FileTextOutlined />}>
                                Question
                            </Menu.Item>
                            <Menu.Item key="/App" icon={<FileTextOutlined />}>
                                Brand
                            </Menu.Item>
                            <Menu.Item key="/User" icon={<UserOutlined />}>
                                User
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background flex-between">

                            {React.createElement(
                                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                {
                                    className: "trigger text-white f-s-20",
                                    onClick: this.toggle,
                                }
                            )}
                            {accountHtml}
                        </Header>
                        <Content className="site-layout-background p-20">
                            {children}
                            <Footer />
                        </Content>
                    </Layout>
                </Layout>
            </>
        );
    }
}

export default withRouter(MyLayout)
