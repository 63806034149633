import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SnippetsOutlined, CloseCircleFilled } from '@ant-design/icons'
import CoursewareUpload from '../paper/CoursewareUpload'
import Player from 'components/common/Player'
import { GetCourseware, SaveCourseware, DeleteCourseware } from "../../api/gallery";
import { Form, InputNumber, Result, Input, Modal, Slider, message, Space, Table, Button } from 'antd'
const CoursewareModal = ({ open, close, fetch, id }) => {
    const [clear, setClear] = useState(false)
    const [imageList, setImageList] = useState([])
    const [files, setFiles] = useState([])
    useEffect(() => {
        if (open) {
            getCourseware()
        }
    }, [open])
    const getCourseware = async () => {
        const { data: res } = await GetCourseware(id)
        if (res.isSuccess) {
            setImageList(res.data)
        }
    }
    const deleteCourseware = (coursewareId) => {
        return async () => {
            const { data: res } = await DeleteCourseware(coursewareId, id)
            if (res.isSuccess) {
                message.success(res.message);
                getCourseware()
            }
        }

    }
    const onUpload = async () => {
        const { data: res } = await SaveCourseware(files, id)
        if (res.isSuccess) {
            message.success(res.message)
            setFiles([])
            setClear(true)
            getCourseware()
        }
    }
    const onCancel = () => {
        close()
    }
    return (
        <Modal width={'50vw'} title="Courseware" visible={open} onCancel={onCancel} footer={null}>
            <div className='courseware-container'>
                {
                    imageList.length > 0 ?
                        (<ul>
                            {
                                imageList.map(item => (
                                    <li key={item.id}>
                                        <i>


                                            <CloseCircleFilled onClick={deleteCourseware(item.id)}
                                                className="courseware-delete-icon" />
                                            {
                                                item.contentType.indexOf('image') !== -1 ? (<img src={item.fileURL} alt={item.name} />) : (<Player fluid src={item.fileURL}></Player>)
                                            }

                                        </i>
                                    </li>
                                ))
                            }
                        </ul>) :
                        (<Result
                            status="warning"
                            title="There is no courseware for this question."
                        />)
                }
                <CoursewareUpload isClear={clear} multiple={true} getFile={setFiles} />
                {files.length > 0 ? (<Button block type="primary" onClick={onUpload}>Upload</Button>) : ''}

            </div>
        </Modal>
    )
}

export default CoursewareModal
