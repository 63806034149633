import request from '../utils/request'
import { sessionGet } from 'utils/session'
const BASE_URL = '/api/ScoreManagemnet'

export function GetScoreList(papername, customername, skip = 0, take = 20) {
    const token = sessionGet('token')
    return request({
        url: `${BASE_URL}/GetList`,
        method: 'get',
        params: {
            papername,
            customername,
            skip,
            take
        },
        headers: { Authorization: `${token}` }
    })
}
export function GetHistoryList(email, skip = 0, take = 20, appkey, quiz) {
    const token = sessionGet('token')
    return request({
        url: `${BASE_URL}/GetHistoryList`,
        method: 'get',
        params: {
            email, appkey, quiz, skip, take
        },
        headers: { Authorization: `${token}` }
    })
}
export function GetEmailList(email, skip = 0, take = 20, appkey, quiz) {
    const token = sessionGet('token')
    return request({
        url: `${BASE_URL}/GetEmailList`,
        method: 'get',
        params: {
            email, appkey, quiz, skip, take
        },
        headers: { Authorization: `${token}` }
    })
}
export function GetScoreHistory(uid, pid) {
    const token = sessionGet('token')
    return request({
        url: `${BASE_URL}/GetList`,
        method: 'get',
        params: {
            uid, pid
        },
        headers: { Authorization: `${token}` }
    })
}


