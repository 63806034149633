import React, { Component } from 'react';
import { Route,Switch,Redirect } from 'react-router-dom';

import "assets/css/reset.css"
import 'antd/dist/antd.css'
import "assets/css/base.css"
import routerMap from 'utils/routerMap';
import FrontendAuth from './components/common/FrontendAuth'
export default class App extends Component {
  static displayName = App.name;
  render () {
    return (
      <>
          <Switch>
           <FrontendAuth routerConfig={routerMap} />
          </Switch>
        </>
    );
  }
}
