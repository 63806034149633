import React, { Component } from 'react'
import { getAuth } from '../api/auth';
import { withRouter } from 'react-router';
import { sessionSet } from 'utils/session';
import loginLogo from '../assets/img/login-logo.png'
import { Row,Col,Form, Input, Button, Checkbox,message,notification } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
class SignIn extends Component {
    state = {

    }
    openNotificationWithIcon = (type,message,description='') => {
        notification[type]({
          message,
          description
        });
      };
    save = () =>{

    }
    login = async ({email,password}) =>{
        const {data:res} = await getAuth(email,password)
        if(res.isSuccess){
            this.openNotificationWithIcon('success',res.data.email,'Welcome back!')
            sessionSet('token',`Bearer ${res.data.token}`)
            sessionSet('email',res.data.email)
            sessionSet('type',res.data.type)
            this.props.history.push('/')
        }
    }
    onFinish = (values) => {
        this.login(values)
    };
    onFinishFailed = (errorInfo) => {
    };
        render() {
            return (

                    <Row>
                        <Col span={12}>
                            <div className="login-background"></div>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={4}></Col>
                                <Col span={18}>
                                    <div className="login-container">
                                        <div className="login-box">
                                         <h1><img src={ loginLogo} alt="Training" /></h1>
                                         <h2>Login Your Account</h2>
                                        <Form
                                            name="basic"
                                            labelCol={{ span: 5 }}
                                            wrapperCol={{ span: 16 }}
                                            onFinish={this.onFinish}
                                            onFinishFailed={this.onFinishFailed}
                                            autoComplete="on"
                                        >
                                            <Form.Item
                                                label="Email"
                                                name="email"

                                                rules={[{ required: true, message: 'Please input your username!' }]}
                                            >
                                                <Input placeholder="Your Email" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[{ required: true, message: 'Please input your password!' }]}
                                            >
                                                <Input.Password placeholder="Your Password" />
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                                                <Button type="primary" htmlType="submit" block>
                                                    Login
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                        </div>
                                        </div>
                                </Col>
                                <Col span={4}></Col>
                            </Row>

                        </Col>

                    </Row>


            )
        }
    }

export default withRouter(SignIn)
