import request from '../utils/request'
import { sessionGet } from 'utils/session'
const BASE_URL = '/api/QuestionPaper'

export function GetPaperList(keyword,skip=0,take=20){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetList`,
        method:'get',
        params:{
            keyword,
            skip,
            take
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetPaper(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Get`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetCustomerPaperRelation(pid){
    return request({
        url:`${BASE_URL}/GetCustomerPaperRelation`,
        method:'get',
        params:{
            pid
        }
    })
}
export function SavePaper(DataString,cover){
    const token = sessionGet('token')
    let formData = new FormData()
    formData.append('cover',cover)
    formData.append('DataString',JSON.stringify (DataString))
    return request({
        url:`${BASE_URL}/Save`,
        method:'post',
        data:formData,
        headers: {  Authorization: `${token}`  }
    })
}
export function DeletePaper(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Delete`,
        method:'post',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function SaveCustomerPaperRelation(customer=0,paperIDList,paperID=0,customerIDList,paperNameList,customerEmailList){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetCustomerPaperRelation`,
        method:'post',
        data:{
            customer,paperIDList,paperID,customerIDList,paperNameList,customerEmailList
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetTopicList(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetTopicList`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function SaveTopicList(paperID,list){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/SaveTopicList`,
        method:'post',
        data:{
            paperID,list
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetAllQuestion(){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetAllQuestion`,
        method:'get',
        headers: {  Authorization: `${token}`  }
    })
}
export function GetAllApp(){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetAllApp`,
        method:'get',
        headers: {  Authorization: `${token}`  }
    })
}
export function GetPaperApp(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetPaperApp`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetPaperQuestion(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetPaperQuestion`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function SavePaperQuestion(id,list){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/SavePaperQuestion`,
        method:'post',
        params:{
            id
        },
        data:list,
        headers: {  Authorization: `${token}`  }
    })
}
export function SavePaperApp(id,list){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/SavePaperApp`,
        method:'post',
        params:{
            id
        },
        data:list,
        headers: {  Authorization: `${token}`  }
    })
}
export function GetShareLink(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetShareLink`,
        method:'get',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function SaveScore(id, score) {
    const token = sessionGet('token')
    return request({
        url: `${BASE_URL}/SaveQuestionScore`,
        method: 'post',
        params: {
            id,score
        },
        headers: { Authorization: `${token}` }
    })
}