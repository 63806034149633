import React, { Component } from 'react'
import {Col,Row,List,message,Button,Table,Space } from 'antd'
import {GetList,BeginExam} from '../api/training'
import {EditOutlined} from '@ant-design/icons'
import logo from '../assets/img/login-logo-blue2.png'
class TrainingList extends Component {
    static propTypes = {

    }
    state = {
        data:[],
        key:'',
        keyword:'',
        skip:0,
        take:20
    }
    columns = [
        {
            title:'Paper Name',
            dataIndex:'paperName',
        },
        {
            title:'Question Count',
            dataIndex:'questionCount',
        },
        {
            title:'Action',
            dataIndex:'paperID',
            render:(text,record)=>(
                <Space>
                        <Button type="primary" onClick={this.beginTraining(record.paperID,record.paperName)} icon={<EditOutlined />}>Training</Button>
                </Space>
            )
        }
    ]
    getTrainingList = async () =>{
        const {keyword,skip,take} = this.state
        let key = this.props.location.search.substr(5)
        this.setState({key})
        const {data:res} = await GetList(key,keyword,skip,take)
        if(res.isSuccess){
            message.success(res.message)
            this.setState({data:res.data})
        }
    }
    componentDidMount = () =>{
        this.getTrainingList()
    }
    beginTraining = (paperID,paperName) =>{
        return async ()=>{
            const {key} = this.state
            const {data:res} = await BeginExam(key,paperID)
            if(res.isSuccess){
                message.success(res.message)
                this.props.history.push('/training',{usid:res.data,key:this.state.key,paperName,paperID})
            }

        }
    }
    render() {
        const {data} = this.state
        return (
            <>
            <h1 className="logo-container">
                <img src={logo} alt="logo" />
            </h1>
            <Row>
                <Col span={14} offset={5}>
                    <Table rowKey={record=>record.paperID}  columns={this.columns} dataSource={data} />
                </Col>
            </Row>
            </>
        )
    }
}

export default TrainingList