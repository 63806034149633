import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SnippetsOutlined } from '@ant-design/icons'
import { Form, InputNumber, Input, Modal, Slider, message, Space, Table } from 'antd'
const Score = ({ initialValue, open, close, api, fetch }) => {

  const [loading, setLoading] = useState(false)
  const onChange = (id) => {
    return async (e) => {
      const { data: res } = await api(id, e)
      if (res.isSuccess) {

      }
    }
  }
  const onCancel = () => {
    close()
    fetch()
  }
  const columns = [
    {
      title: "Topic",
      dataIndex: "topic",
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text, record) => (
          <InputNumber prefix={<SnippetsOutlined />} placeholder='Score' defaultValue={record.score} onChange={onChange(record.scoreID)}></InputNumber>
      )
    }
  ]
  return (
    <Modal confirmLoading={loading} width={'50vw'} title="Score" visible={open} onCancel={onCancel} footer={null}>
      <Table
        className="min-layout-height"
        loading={loading}
        rowKey={(record) => record.questionID}
        columns={columns}
        dataSource={initialValue}
        pagination={false}
      />
    </Modal>
  )
}

Score.propTypes = {

}

export default Score
