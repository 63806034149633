import React from 'react';
import { Player } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css'
export default ({poster,src}) => {
  return (
    <Player
      playsInline
      poster={poster}
      src={src}
    />
  );
};