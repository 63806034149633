import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types'
import { GetPaper } from '../../api/paper'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal,Form,Input,Space,InputNumber,Radio,Button,Switch,message } from 'antd'
const EditPaperModal = ({id,initialValue,open,close,api,fetch}) => {
    const {TextArea} = Input
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false)
    const [a, setA] = useState(initialValue.levelAScore)
    const [b, setB] = useState(initialValue.levelBScore)
    const [c, setC] = useState(initialValue.levelCScore)
    const [d, setD] = useState(initialValue.levelDScore)
    const formItemLayout = {
        labelCol: {
            xs:{
               span:4
            }
          },
        wrapperCol:{
            xs:{
              span:21
            }
        }
    }
    const onChange = (name) => {
        switch (name) {
            case 'a':
                return (e) => {
                    setA(e||100)
                }
            case 'b':
                return (e) => {
                    setB(e||0)
                }
            case 'c':
                return (e) => {
                    setC(e||0)
                }
            case 'd':
                return (e) => {
                    setD(e||0)
                }
            default:
                return
        }
    }
    useEffect(() => {
        if(open){
            form.setFieldsValue(initialValue)
        }
    }, [form, initialValue, open])
    const onFinish = async (values) => {
        setLoading(true)
        const {data:res} = await api({...values,id})
        if(res.isSuccess){
            setLoading(false)
            message.success(res.message)
            fetch()
            resetForm()
        }
    };
    const resetForm = () =>{
        form.resetFields()
        close()
    }
    return (
        <Modal confirmLoading={loading}  width={'50vw'} title="Edit Quiz" visible={open} onOk={form.submit} onCancel={resetForm}>
            <Form  form={form} {...formItemLayout} name="addQuestionForm" onFinish={onFinish} autoComplete="off">
                <Form.Item name="name" label="Name" rules={[{required:true,message:'Missing Name'}]}>
                    <Input placeholder="Quiz Name" />
                </Form.Item>
                <Form.Item name="description" label="Description">
                    <TextArea rows={4} placeholder="Quiz Description" />
                </Form.Item>
                <Form.Item name="status" initialValue={0} label="Status" rules={[{required:true,message:'Missing Status'}]}>
                    <Radio.Group>
                        <Radio value={0}>Inactive</Radio>
                        <Radio value={1}>Active</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name='levelAScore' label="Level A Score">
                    <InputNumber placeholder='score' max={100} value={a} onChange={onChange('a')} />
                </Form.Item>
                <Form.Item name='levelAMessage' label="Level A Evaluate">
                    <Input placeholder='Level A Evaluate' />
                </Form.Item>
                <Form.Item name='levelBScore' label="Level B Score">
                    <InputNumber placeholder='score' max={a - 1} value={b} onChange={onChange('b')} />
                </Form.Item>
                <Form.Item name='levelBMessage' label="Level B Evaluate">
                    <Input placeholder='Level B Evaluate' />
                </Form.Item>
                <Form.Item name='levelCScore' label="Level C Score">
                    <InputNumber placeholder='score' max={b - 1} value={c} onChange={onChange('c')} />
                </Form.Item>
                <Form.Item name='levelCMessage' label="Level C Evaluate">
                    <Input placeholder='Level C Evaluate' />
                </Form.Item>
                <Form.Item name='levelDScore' label="Level D Score">
                    <InputNumber placeholder='score' max={c - 1} value={d} onChange={onChange('d')} />
                </Form.Item>
                <Form.Item name='levelDMessage' label="Level D Evaluate">
                    <Input placeholder='Level D Evaluate' />
                </Form.Item>
            </Form>
        </Modal>
    )
}

EditPaperModal.propTypes = {

}

export default EditPaperModal
