import request from '../utils/request'


export function getAuth(email,password){
    return request({
        method:'post',
        url:`/api/OAuth`,
        data:{
            email,
            password
        }
    })
}