import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SavePaperApp,GetAllApp,GetPaperApp } from '../../api/paper';
import { Modal,Form,Input,Space,Radio,Button,Switch,message,Transfer } from 'antd'
const AppRelationModal = ({id,open,close,fetch}) => {
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [allList,setAllList] = useState([])
    const [loading,setLoading] = useState(false)
    useEffect(() => {
        if(open){
            getQuestion()
            getPaperQuestion()

        }
    }, [open])
    const submit = async () =>{
        setLoading(true)
        const {data:res} = await SavePaperApp(id,selectedKeys.filter(item =>item))
        if(res.isSuccess){
            setLoading(false)
            message.success(res.message)
            closeModal()
            fetch()
        }
    }
    const getPaperQuestion = async () =>{
        const {data:res} = await GetPaperApp(id)
        if(res.isSuccess){
            let selectArray = res.data.map((item)=>{return item.appID })
            setSelectedKeys(selectArray)
        }

    }
    const getQuestion = async () =>{
        const {data:res} = await GetAllApp(id)
        if(res.isSuccess){
            setAllList(res.data)
        }
    }
    const closeModal = () =>{
        setTargetKeys([])
        setSelectedKeys([])
        close()
    }
    const handleChange = (nextTargetKeys, direction, moveKeys) =>{
        setTargetKeys(nextTargetKeys);
    }
    const handleFilter = (inputValue, option) => {
        let currentInputValue = inputValue.toLowerCase()
        let currentOptionValue = option.topic.toLowerCase()
        if (currentOptionValue.indexOf(currentInputValue) !== -1) return true
    }
    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) =>{
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }
    return (
        <Modal confirmLoading={loading} width={'70vw'} height={ '80vw'} title="Brand Relation" visible={open} onOk={submit} onCancel={closeModal}>
                <Transfer
                dataSource={allList}
                titles={['All Brand', 'Selected Brand']}
                targetKeys={selectedKeys}
                filterOption={handleFilter}
                selectedKeys={selectedKeys}
                onChange={handleChange}
                onSelectChange={handleSelectChange}
                render={item => item.name}
                rowKey={item => item.appID}
                showSearch={true}
                listStyle={{
                    width: 400,
                    height: 500,
                }}

                />
        </Modal>
    )
}

AppRelationModal.propTypes = {

}

export default AppRelationModal
