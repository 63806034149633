import React, { Component } from 'react'
import errorUrl from '../assets/img/HTML-404-Page.gif'
import { Button, Result,Row,Col } from 'antd'
export default class Error extends Component {

    render() {
        return (
            <Row>
                <Col span={12}>
                    <div className="login-background"></div>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={4}></Col>
                        <Col span={18}>
                            <div className="login-container">
                                <Result
                                    status="404"
                                    title="404"
                                    subTitle="Sorry, you are not authorized to access this page."
                                    extra={<Button onClick={() => { this.props.history.push('/') }} type="primary">Back Login</Button>}
                                />
                            </div>
                        </Col>
                        <Col span={4}></Col>
                    </Row>

                </Col>

            </Row>

        )
    }
}
