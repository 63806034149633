import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Result, Button, Image, message } from 'antd'
import { RightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import Player from 'components/common/Player'
const { confirm } = Modal
const Gallery = ({ open, close, gallery,name }) => {
    const [isPreviewVisible, setPreviewVisible] = useState(false);
    useEffect(() => {
        if (open) {
            setPreviewVisible(true)
        }
        return () => {

        }
    }, [open])
    const closeModal = () => {
        close()
    }
    return (
        <Modal width={'50vw'} onCancel={closeModal} title={name} visible={gallery.length > 0 ? open : false} footer={null}>
            <Image.PreviewGroup preview={
                {
                    visible: isPreviewVisible,
                    onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
                }
            }>
                <div className="courseware-box">
                    <ul>
                        {
                            gallery.map(item => (
                                <li key={item.id}>
                                    {
                                        item.contentType.indexOf('image') !== -1 ?
                                            (<Image
                                                src={item.src} />) : (<Player title={item.name} src={item.src}></Player>)
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Image.PreviewGroup>
        </Modal>
    )
}

Gallery.propTypes = {

}

export default Gallery
