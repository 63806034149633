import React,{ Component } from 'react';
import { Tag, Input, Tooltip, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {SaveTag,removeTag} from '../../api/question'
class Tags extends Component {
    state = {
        inputVisible: false,
        inputValue: '',
        colorArray:["magenta","red","volcano","orange","gold","lime","green","cyan","blue","geekblue","purple"]
    }
    handleClose = async tag => {
        const { id,fetch }= this.props
        const {data:res} = await removeTag(id,tag)
        if(res.isSuccess){
            fetch()
        }
    };
    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.props;
        if(inputValue.length<=0){
           this.setState({inputVisible:false,inputValue:''})
        }else{
            if (inputValue && tags?.indexOf(inputValue) === -1) {
                this.handleSaveTag()
            }
        }
    };

    handleSaveTag = async () =>{
        const {inputValue} = this.state;
        const { id,fetch }= this.props
          const {data:res} = await SaveTag(id,inputValue)
          if(res.isSuccess){
              this.setState({inputVisible:false,inputValue:''})
              fetch()
          }

    }
    saveInputRef = input => {
        this.input = input;
    };

    render() {
        const { tags } = this.props
        const { inputVisible, inputValue,colorArray } = this.state;
        return (
            <>
                {tags?.map((tag, index) => {
                    const tagElem = (
                        <Tag
                            color={colorArray[index>11?index%11:index]}
                            className="edit-tag"
                            key={tag}
                            closable={true}
                            onClose={() => this.handleClose(tag)}
                        >
                            <span
                            style={{cursor:'pointer'}}
                            >
                                {tag}
                            </span>
                        </Tag>
                    );
                    return tagElem
                    ;
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        className="tag-input"
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                        style={{width:'82px'}}
                    />
                )}
                {!inputVisible && (
                    <Tag className="site-tag-plus" style={{cursor:'pointer',backgroundColor:'#fff',color:'#1890ff',borderColor:'#1890ff'}}  onClick={this.showInput}>
                        <PlusOutlined /> New Tag
                    </Tag>
                )}
            </>
        );
    }
}

export default Tags