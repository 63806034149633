import React,{useEffect} from 'react'
import PropTypes from 'prop-types'
import { Upload, Button,Image,message, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
const UploadCase = ({listType,getFile,getFileURL,fileType}) => {
    useEffect(() => {
        if(fileType){
            if(fileType.indexOf('image')!==-1){
                setType('img')
                setPreviewUrl(`../${getFileURL}`)
            }else if(fileType.indexOf('video')!==-1){
                setType('video')
                setPreviewUrl(`../${getFileURL}`)
            }else{

            }
        }
    }, [fileType, getFileURL])
    const [previewUrl,setPreviewUrl] = React.useState('')
    const [type,setType] = React.useState('')
    const preview = (file) =>{
        const isPictureType = isPicture(file.name)
        const isVideoType = isVideo(file.name)
        if(isPictureType){
            setType('img')
            setPreviewUrl(URL.createObjectURL(file))
            getFile(file)
        }else if(isVideoType){
            setType('video')
            setPreviewUrl(URL.createObjectURL(file))
            getFile(file)
        }else{
            message.error('Please upload pictures or videos')
        }
        return Promise.reject()

    }
    const remove = () =>{
        setPreviewUrl('')
        getFile()
    }
    const isVideo = (name) =>{
        let strFilter=".avi|.wmv|.mpg|.mpeg|.mov|.rm|.ram|.swf|.flv|.mp4|.mp3|.wma|.avi|.rm|.rmvb|.flv|.mpg|.mkv"
        if(name.indexOf(".")>-1)
        {
            let p = name.lastIndexOf(".");
            let strPostfix=name.substring(p,name.length) + '|';
            strPostfix = strPostfix.toLowerCase();
            if(strFilter.indexOf(strPostfix)>-1)
            {
                return true;
            }
        }
        return false;
    }
    const isPicture = (name) =>{
        //判断是否是图片 - strFilter必须是小写列举
        let strFilter=".jpeg|.gif|.jpg|.png|.bmp|.pic|.svg|"
        if(name.indexOf(".")>-1)
        {
            let p = name.lastIndexOf(".");
            let strPostfix=name.substring(p,name.length) + '|';
            strPostfix = strPostfix.toLowerCase();
            if(strFilter.indexOf(strPostfix)>-1)
            {
                return true;
            }
        }
        return false;
    }
    const img = (
        <div className="preview-box">
            <Image src={previewUrl} width="100%" />
        </div>
    )
    const video = (
        <div className="preview-box">
            <video style={{display:previewUrl?'block':'none'}} src={previewUrl} width="100%" controls></video>
        </div>
    )
    return (
        <>
        <Space>
        <Upload showUploadList={false}  maxCount={1} onRemove={remove} beforeUpload={preview} listType={listType} >

                <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
        <Button onClick={remove} style={{display:`${previewUrl.length>0?'block':'none'}`}} type="default" danger>Remove</Button>
        </Space>
        <div className="preview-container" style={{display:`${previewUrl.length>0?'block':'none'}`}}>
            {type==='img'?img:type==='video'?video:''}
        </div>
        </>
    )
}

UploadCase.propTypes = {

}

export default UploadCase
