import request from '../utils/request'
import { sessionGet } from 'utils/session'
const BASE_URL = '/api/App'

export function AppGetList(keyword,skip=0,take=20){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/GetList`,
        method:'get',
        params:{
            keyword,
            skip,
            take
        },
        headers: {  Authorization: `${token}`  }
    })
}

export function AppSave({name,description,status}){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Save`,
        method:'post',
        data:{
            name,
            description,
            status
        },
        headers: {  Authorization: `${token}`  }
    })
}

export function AppDelete(id){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Delete`,
        method:'post',
        params:{
            id
        },
        headers: {  Authorization: `${token}`  }
    })
}