import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Footer from '../components/layout/Footer';
import { AppGetList, AppSave, AppDelete } from '../api/app';
import MyLayout from './Layout';
import { CloseOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Table, Divider, Space, Button, message, Modal } from 'antd';
import AddAppModal from 'components/app/AddAppModal';
const { confirm } = Modal
export class AppPage extends Component {
    static propTypes = {
    }
    state = {
        data: [],
        total:0,
        keyword:'',
        loading: false,
        addModalShow: false,
        editModalShow: false,
    }
    fetchData = async (keyword = '', skip  = 0, take = 10) => {
        this.setState({ loading: true })
        const { data: res } = await AppGetList(keyword, skip, take)
        if (res.isSuccess) {
            this.setState({ data: res.data, loading: false,total:res.totalCount })
        }
    }
    onSearch = (val) => {
        this.setState({keyword:val})
        this.fetchData(val)
    }
    addModalOpen = () => {
        this.setState({ addModalShow: true })
    }
    deleteQuestion = async (id) => {
        const { data: res } = await AppDelete(id)
        if (res.isSuccess) {
            message.success(res.message)
            this.fetchData()
        }
    }
    deleteModalOpen = (id) => {
        let that = this
        return () => {
            confirm({
                title: 'Do you Want to delete these items?',
                icon: <ExclamationCircleOutlined />,
                // content: 'Some descriptions',
                onOk() {
                    return that.deleteQuestion(id)
                },
                onCancel() {

                },
            });
        }
    }
    modalClose = () => {
        this.setState({ addModalShow: false, editModalShow: false })
    }
    paginationChange = (current,pageSize) =>{
        const {keyword} = this.state
        this.fetchData(keyword,(current-1)*pageSize,pageSize)
    }
    componentDidMount = () => {
        this.fetchData()
    }
    componentDidUpdate = () => {

    }
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                switch (record.status) {
                    case 0:
                        return 'Inactive'
                    case 1:
                        return 'Active'
                    default:
                        return 'Inactive'
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.canDel ? <Button type="danger" onClick={this.deleteModalOpen(record.id)} icon={<CloseOutlined />}>Delete</Button> : ''
                    }
                </Space>
            )
        },
    ];
    render() {
        const { loading, addModalShow,total } = this.state
        return (
            <MyLayout>
                <div className="table-top">
                    <div style={{ alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Input.Search
                            size="large"
                            placeholder="Search Brand"
                            style={{ width: '300px' }}
                            onSearch={this.onSearch}
                            enterButton />
                        <Button type="primary"
                            style={{ height: '40px' }}
                            onClick={this.addModalOpen}
                            icon={<PlusOutlined />}>Add Brand</Button>
                    </div>

                    <AddAppModal
                        open={addModalShow}
                        api={AppSave}
                        close={this.modalClose}
                        fetch={this.fetchData}
                    />


                </div>
                <Divider />
                <Table
                    className='min-layout-height'
                    loading={loading}
                    rowKey={record => record.id}
                    pagination={{
                        total,
                        pageSizeOptions:[10,20,30,40,50],
                        onChange:this.paginationChange
                    }}
                    columns={this.columns}
                    dataSource={this.state.data}
                />
            </MyLayout>
        )
    }
}
export default AppPage;
