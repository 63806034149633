import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Result,Button } from 'antd'
import {RightOutlined} from '@ant-design/icons'
const Tips = ({ open, close, next, tips, result }) => {

    const nextQuestion = () =>{
        close()
        next()
    }
    return (
        <Modal width={'50vw'} closable={false} maskClosable={false} title="Tips" visible={open} footer={null}>
            <div className='tips-container'>
                <div className='tips-result'>
                    <Result
                        status={result ? 'success' : 'error'}
                        title={tips}
                        extra={
                            <Button
                                shape='circle'
                                size='large'
                                icon={<RightOutlined
                                onClick={nextQuestion}
                            />} ></Button>
                        }
                    />
                </div>
            </div>
        </Modal>
    )
}

Tips.propTypes = {

}

export default Tips
