import request from '../utils/request'

const BASE_URL = '/api/Training'

export function GetList(key,keyword,skip=0,take=20){
    return request({
        url:`${BASE_URL}/GetList`,
        method:'get',
        params:{
            key,
            keyword,
            skip,
            take
        },
    })
}
export function GetKey(key){
    return request({
        url:`${BASE_URL}/GetDecodeKey`,
        method:'get',
        params:{
            key
        },
    })
}
export function GetTopic(key,usid,sort){
    return request({
        url:`${BASE_URL}/GetTopic`,
        method:'get',
        params:{
            key,
            usid,
            sort
        },
    })
}
export function GetExamLeft(usid,index){
    return request({
        url:`${BASE_URL}/GetExamLeft`,
        method:'get',
        params:{
            usid,
            index
        },
    })
}
export function BeginExam(key,paperid){
    return request({
        url:`${BASE_URL}/BeginExam`,
        method:'get',
        params:{
            key,
            paperid
        },
    })
}
export function SaveAnswer(key,usid,topicid,options,useranswerid){
    return request({
        url:`${BASE_URL}/SaveAnswer`,
        method:'post',
        data:{
            key,usid,topicid,options,useranswerid
        },
    })
}
export function FinishExam(key,usid){
    return request({
        url:`${BASE_URL}/FinishExam`,
        method:'post',
        params:{
            key,
            usid
        },
    })
}
export function Test(){
    return request({
        url:`${BASE_URL}/Test`,
        method:'get',
    })
}

export function Share(email,sharekey){
    return request({
        url:`${BASE_URL}/Share`,
        method:'post',
        params:{
            email,
            sharekey
        },
    })
}