import React, { Component } from "react";
import PropTypes from "prop-types";
import MyLayout from "./Layout";
import Courseware from '../components/paper/CoursewareModal'
import Footer from "../components/layout/Footer";
import {
    GetTopicList,
    GetPaperList,
    GetPaper,
    GetCustomerPaperRelation,
    SavePaper,
    DeletePaper,
    GetShareLink,
    SaveCustomerPaperRelation,
    SaveScore
} from "../api/paper";

import {
    ReconciliationOutlined,
    ApiOutlined,
    SelectOutlined,
    EditOutlined,
    CloseOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    Layout,
    Input,
    Table,
    Divider,
    Space,
    Button,
    message,
    Modal,
} from "antd";
import Score from 'components/paper/Score';
import AddPaperModal from "components/paper/AddPaperModal";
import EditPaperModal from "components/paper/EditPaperModal";
import AddPaperFormModal from "components/paper/AddQuestionFromModal";
import AppRelationModal from "components/paper/AppRelationModal";
import Result from "components/paper/Result";
import { GetPaperQuestion } from "api/paper";
const { confirm } = Modal;
export class Paper extends Component {
    static propTypes = {};
    state = {
        id: "",
        data: [],
        loading: false,
        keyword: '',
        initialValue: {},
        addModalShow: false,
        editModalShow: false,
        addQuestionFormShow: false,
        relationModalShow: false,
        resultModalShow: false,
        scoreModalShow: false,
        coursewareModalShow: false,
        total: 0,
    };
    fetchData = async (keyword = '', skip = 0, take = 10) => {
        this.setState({
            loading: true,
        });
        const { data: res } = await GetPaperList(keyword, skip, take);
        if (res.isSuccess) {
            this.setState({
                data: res.data,
                loading: false,
                total: res.totalCount
            });
        }
    };
    onSearch = (val) => {
        this.setState({ keyword: val })
        this.fetchData(val)
    }
    addModalOpen = () => {
        this.setState({
            addModalShow: true,
        });
    };
    editModalOpen = (id) => {
        return async () => {
            const { data: res } = await GetPaper(id);
            if (res.isSuccess) {
                this.setState({
                    id,
                    initialValue: res.data,
                    editModalShow: true,
                });
            }
        };
    };
    addQuestionFormModalOpen = (id) => {
        return async () => {
            this.setState({
                addQuestionFormShow: true,
                id,
            });
        };
    };
    relationModalOpen = (id) => {
        return async () => {
            this.setState({
                relationModalShow: true,
                id,
            });
        };
    };
    scoreModalOpen = (id) => {
        return async () => {
            const { data: res } = await GetPaperQuestion(id)
            if (res.isSuccess) {
                this.setState({
                    initialValue: res.data,
                    scoreModalShow: true,
                })
            }
        }
    }
    coursewareModalOpen = (id) => {
        return () => {
            this.setState({
                id,
                coursewareModalShow: true,
            })
        }
    }
    setResultModalOpen = (id) => {
        return async () => {
            this.setState({
                resultModalShow: true,
                id,
            });
        };
    };
    deleteQuestion = async (id) => {
        const { data: res } = await DeletePaper(id);
        if (res.isSuccess) {
            message.success(res.message);
            this.fetchData();
        }
    };
    deleteModalOpen = (id) => {
        let that = this;
        return () => {
            confirm({
                title: "Do you Want to delete these items?",
                icon: <ExclamationCircleOutlined />,
                // content: 'Some descriptions',
                onOk() {
                    return that.deleteQuestion(id);
                },
                onCancel() {
                },
            });
        };
    };
    modalClose = () => {
        this.setState({
            addModalShow: false,
            editModalShow: false,
            addQuestionFormShow: false,
            relationModalShow: false,
            resultModalShow: false,
            scoreModalShow: false,
            coursewareModalShow: false,
            initialValue: {},
        });
    };
    paginationChange = (current, pageSize) => {
        const { keyword } = this.state
        this.fetchData(keyword, (current - 1) * pageSize, pageSize)
    }
    shareModal = (id) => {
        return async () => {
            const { data: res } = await GetShareLink(id);
            if (res.isSuccess) {
                let thisurl = 'https://training.eurofase.com/ui';
                let stringindex = thisurl.indexOf("ui")
                let copyurl = thisurl.substring(0, stringindex)
                this.copy(copyurl + "s?code=" + res.data);
                message.success('Copied to pasteboard')
            }

        };
    };
    copy = (text) => {
        var input = document.createElement('input');
        input.setAttribute('readonly', 'readonly');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var res = document.execCommand('copy');
        document.body.removeChild(input);
        return res;
    }
    componentDidMount = () => {
        this.fetchData();
    };
    componentDidUpdate = () => { };
    columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "Question Count",
            dataIndex: "questionCount",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="dashed"
                        onClick={this.addQuestionFormModalOpen(record.id)}
                        icon={<SelectOutlined />}
                    >

                        Select Question
                    </Button>
                    <Button
                        disabled={record.status === 'Inactive' ? true : false}
                        type="default"
                        onClick={this.relationModalOpen(record.id)}
                        icon={<ApiOutlined />}
                    >

                        Relation
                    </Button>
                    {/* <Button
                        type="default"
                        onClick={this.coursewareModalOpen(record.id)}
                        icon={<ReconciliationOutlined />}
                    >
                        Courseware
                    </Button> */}
                    <Button
                        type="default"
                        onClick={this.scoreModalOpen(record.id)}
                        icon={<ReconciliationOutlined />}
                    >
                        Score
                    </Button>
                    <Button
                        type="primary"
                        onClick={this.editModalOpen(record.id)}
                        icon={<EditOutlined />}
                    >
                        Edit
                    </Button>
                    {record.canDel ? (
                        <Button
                            type="danger"
                            onClick={this.deleteModalOpen(record.id)}
                            icon={<CloseOutlined />}
                        >

                            Delete
                        </Button>
                    ) : (
                        ""
                    )}
                    {record.questionCount > 0 ? (
                        <Button
                            type="primary"
                            onClick={this.shareModal(record.id)}
                            icon={<EditOutlined />}
                        >Share
                        </Button>
                    ) : ("")
                    }
                </Space>
            ),
        },
    ];
    render() {
        const {
            resultModalShow,
            loading,
            initialValue,
            addModalShow,
            editModalShow,
            addQuestionFormShow,
            scoreModalShow,
            coursewareModalShow,
            id,
            relationModalShow,
            total,
        } = this.state;
        return (
            <MyLayout>
                <div className="table-top">
                    <div style={{ alignItems: 'center', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Input.Search
                            size="large"
                            placeholder="Search Quiz"
                            style={{ width: '300px' }}
                            onSearch={this.onSearch}
                            enterButton />
                        <Button
                            style={{ height: '40px' }}
                            type="primary"
                            onClick={this.addModalOpen}
                            icon={<PlusOutlined />}
                        >

                            Add Quiz
                        </Button>
                    </div>

                    <AddPaperModal
                        open={addModalShow}
                        close={this.modalClose}
                        api={SavePaper}
                        fetch={this.fetchData}
                    />
                    <AddPaperFormModal
                        open={addQuestionFormShow}
                        close={this.modalClose}
                        id={id}
                        fetch={this.fetchData}
                    />
                    <EditPaperModal
                        id={id}
                        open={editModalShow}
                        close={this.modalClose}
                        initialValue={initialValue}
                        api={SavePaper}
                        fetch={this.fetchData}
                    />
                    <AppRelationModal
                        id={id}
                        open={relationModalShow}
                        close={this.modalClose}
                        fetch={this.fetchData}
                    />
                    <Result id={id}
                        open={resultModalShow}
                        close={this.modalClose} />
                    <Score
                        open={scoreModalShow}
                        initialValue={initialValue}
                        fetch={this.fetchData}
                        api={SaveScore}
                        close={this.modalClose} />
{/*                     <QuestionTransfer
                        open={addQuestionFormShow}
                        close={this.modalClose}
                        id={id}
                        fetch={this.fetchData}
                                                /> */}
                        {/*                     <Courseware
                        id={id}
                        open={coursewareModalShow}
                        fetch={this.fetchData}
                        close={this.modalClose} /> */}
                </div>
                <Divider />
                <Table
                    className="min-layout-height"
                    loading={loading}
                    rowKey={(record) => record.id}
                    pagination={{
                        total,
                        pageSizeOptions: [10, 20, 30, 40, 50],
                        onChange: this.paginationChange
                    }}
                    columns={this.columns}
                    dataSource={this.state.data}
                />
            </MyLayout>
        );
    }
}

export default Paper;
