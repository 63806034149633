import request from '../utils/request'
import { sessionGet } from 'utils/session'
const BASE_URL = '/api/Courseware'

export function GetCourseware(questionid){
    return request({
        url:`${BASE_URL}/GetCoursewareByQuestion`,
        method:'get',
        params:{
            questionid,
        },
    })
}

export function SaveCourseware(files,questionid){
    const token = sessionGet('token')
    let formData = new FormData();
    files.forEach((item)=>{
        formData.append('files',item)
    })
    return request({
        url:`${BASE_URL}/Save`,
        method:'post',
        params:{
            questionid,
        },
        data:formData,
        headers: {  Authorization: `${token}`  }
    })
}
export function DeleteCourseware(id,questionid){
    const token = sessionGet('token')
    return request({
        url:`${BASE_URL}/Delete`,
        method:'post',
        params:{
            id,
            questionid
        },
        headers: {  Authorization: `${token}`  }
    })
}
export function GetCoursewareFile(id){
    return request({
        url:`${BASE_URL}/GetCoursewareFile`,
        method:'get',
        params:{
            id
        }
    })
}