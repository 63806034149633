import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification, Alert, Checkbox, Image, Radio, Space, Col, Button, List, message, Row, Form, Statistic } from 'antd'
import { LeftOutlined, RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import logo from '../assets/img/login-logo-blue.png'
import Tips from '../components/training/Tips'
import Gallery from '../components/training/Gallery'
import { GetCourseware } from '../api/gallery'
import { GetKey, GetTopic, GetExamLeft, BeginExam, SaveAnswer, FinishExam, Test } from '../api/training'
const { Countdown } = Statistic
const deadline = Date.now() + 1000 * 10;
const { confirm } = Modal
export default class Training extends Component {
    static propTypes = {

    }
    ul = createRef()
    state = {
        usid: '',
        key: '',
        sort: 1,
        tips: '',
        result: false,
        isDone: false,
        gallery: [],
        paperName: '',
        optionType: 0,
        selectOptionID: [],
        isAllAnswer: false,
        questionList: [],
        optionList: [],
        totalCount: 0,
        paperTopicID: '',
        topic: '',
        fileContentType: '',
        fileURL: '',
        userAnswerID: '',
        answerLetter: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        tipsModalShow: false,
        galleryModalShow: true,
    }
    componentDidMount = () => {
        const { usid, key, paperName, paperID } = this.props.location.state
        this.setState({ usid, key, paperName })
        this.getCurrentQuestion(1, key, usid)()
        this.getExamLeft(usid)
    }
    componentWillUnmount = () => {
    }
    galleryModalClose = () => {
        this.setState({ galleryModalShow: false })
    }
    getGallery = async (id) => {
        const { data: res } = await GetCourseware(id)
        if (res.isSuccess) {
            let gallery = res.data.map(item => { return { name: item.name, id: item.id, src: item.fileURL, contentType: item.contentType } })
            this.setState({ gallery, galleryModalShow: true })
        }
    }
    optionChange = (selectOptionID) => {
        this.setState({ selectOptionID })
    }
    singleOptionChange = (e) => {
        let value = e.target.value
        this.setState({ selectOptionID: [value] })
    }
    getPrevTopic = () => {
        const { sort } = this.state
        if (sort !== 1) {
            this.getCurrentQuestion(sort - 1)()
        }
    }
    getNextTopic = () => {
        const { sort, totalCount } = this.state
        let that = this
        this.isAllQuestionFinish(() => {
            if (sort < totalCount) {
                that.getCurrentQuestion(sort + 1)()
            } else {
                that.getCurrentQuestion(1)()
            }
        })

    }
    isAllQuestionFinish = (func) => {
        const { totalCount } = this.state
        let isDoneQuestionCount = this.ul.current.querySelectorAll('.is-done').length
        let that = this
        console.log(isDoneQuestionCount,totalCount)
        if (isDoneQuestionCount >= totalCount) {
            confirm({
                title: 'Are you sure to hand in your Quiz?',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                    that.finishExam()
                }
            });
        } else {
            func()
        }
    }
    saveOption = async () => {
        // 如果是最后一项就是提交，否则下一项
        const { totalCount, isDone, sort, optionType, key, usid, paperTopicID, selectOptionID, userAnswerID } = this.state
        console.log(selectOptionID)
        if (isDone || (selectOptionID.length <= 0 && optionType === 0)) {
            if (sort < totalCount) {
                this.getCurrentQuestion(sort + 1)()
            } else {
                this.getCurrentQuestion(1)()
            }
        } else {
            const { data: res } = await SaveAnswer(key, usid, paperTopicID, selectOptionID, userAnswerID)
            if (res.isSuccess) {
                this.setState({
                    result: res.data.result,
                    tips: res.data.tips,
                    tipsModalShow: true
                })
                this.getExamLeft(usid)

            } else {
            }
        }

    }
    getExamLeft = async (usid) => {
        const { sort } = this.state
        const { data: res } = await GetExamLeft(usid, sort)
        if (res.isSuccess) {
            this.getCurrentQuestion(sort)
            let questionList = res.data.sort((prev, next) => prev['sort'] - next['sort'])
            this.setState({ questionList })
        }
    }
    getCurrentQuestion = (currentSort, initKey, initUsid) => {
        return async () => {
            const { key, usid, answerLetter, sort } = this.state
            const { data: res } = await GetTopic(key || initKey, usid || initUsid, currentSort || sort)
            if (res.isSuccess) {
                const { isDone, optionType, userAnswerID, topic, fileContentType, fileURL, optionList, totalCount, paperTopicID } = res.data
                let list = optionList.map((item, index) => {
                    return { label: `${answerLetter[index]}.${item.optionValue}`, value: item.id, isUserAnswer: item.isUserAnswer }
                }
                )
                let selectOptionArray = optionList.filter((item) => {
                    return item.isUserAnswer
                })
                let selectOptionID = selectOptionArray.map(item => item.id)
                this.setState({
                    isDone, optionType, selectOptionID, userAnswerID, topic, fileContentType, fileURL, sort: currentSort, optionList: list, totalCount, paperTopicID
                })
                if (!res.data.isDone) {
                    this.getGallery(res.data.questionID)
                }

            }

        }
    }
    openNotificationWithIcon = (type, message, description = '') => {
        notification[type]({
            message,
            description
        });
    };
    finishExam = async () => {
        const { key, usid } = this.state
        const { data: res } = await FinishExam(key, usid)
        if (res.isSuccess) {
            this.openNotificationWithIcon('success', 'End of exam!', 'Thank you for completing the exam!')
            this.props.history.push(`/finish`, {
                key,
                level: res.data.level || 'E',
                message: res.data.message || 'You still have to work hard',
                score: res.data.score
            })
        }
    }
    getSingleDefaultValue = (optionType, optionList) => {
        if (optionType === 1) {
            let item = optionList.find(item => item.isUserAnswer)
            if (item) {
                return item.value
            } else {
                return optionList[0].value
            }

        }

    }
    render() {
        let media = ''
        const { galleryModalShow, gallery, isDone, paperName, tips, optionType, totalCount, selectOptionID, questionList, topic, fileContentType, fileURL, sort, optionList, tipsModalShow, result } = this.state
        const multiple = (<Checkbox.Group disabled={isDone} value={selectOptionID} options={optionList} onChange={this.optionChange} />)
        const single = (
            <Radio.Group disabled={isDone} defaultValue={this.getSingleDefaultValue(optionType, optionList)} onChange={this.singleOptionChange}>
                <Space direction="vertical" size={30}>
                    {optionList.map(item => {
                        return (<Radio key={item.value} value={item.value}>{item.label}</Radio>)
                    })}
                </Space>
            </Radio.Group>
        )
        const isImageOrVideo = () => {
            if (!fileContentType) return
            if (fileContentType.indexOf('image') != -1) {
                media = (<Image width={400} src={fileURL} alt={topic} />)
            } else if (fileContentType.indexOf('video') != -1) {
                return (
                    media = (<video width="100%" src={fileURL} alt={topic} controls />)
                )
            }
        }
        isImageOrVideo()
        return (
            <>

                <Gallery
                    name={topic}
                    gallery={gallery}
                    close={this.galleryModalClose}
                    open={galleryModalShow} />
                <div className='testpanel'>
                    <div className='testpanel-header'>
                        <h1>Training - {paperName}</h1>
                        <Button type="primary" onClick={this.finishExam}>Examination Submit</Button>
                    </div>
                    <Row>
                        <Col span={4} offset={1}>
                            <div className="test-container">
                                <div className='testpanel-nav'>
                                    <p className="nav-title">Choice</p>
                                    <ul className="testpanel-ul" ref={this.ul}>
                                        {
                                            questionList.map((item, index) =>
                                                (<li key={item['sort']} onClick={this.getCurrentQuestion(item['sort'])} className={`${item['sort'] === this.state.sort ? 'active' : ''} ${item.isDone ? 'is-done' : ''}`}>{item['sort']}</li>)
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col span={18}>
                            <div className="test-container">
                                <div className='testpanel-main'>
                                    <h2>( {sort} ) ({optionType === 0 ? "multiple-choice" : "single-choice"}) {topic}<span></span> </h2>
                                    <div className="questions">
                                        <div className="question-option">
                                            {optionType === 0 ? multiple : single}
                                        </div>
                                        <div className="question-media">
                                            {media}
                                        </div>
                                    </div>
                                    <div className="question-switch">
                                        <Space>
                                            <Button disabled={sort === 1} onClick={this.getPrevTopic} icon={<LeftOutlined />}>Prev</Button>
                                            {/*                                             <Button type="primary" onClick={this.saveOption} disabled={isDone}>Save</Button> */}
                                            <Button
                                                disabled={sort === totalCount && isDone}
                                                type='primary' onClick={this.saveOption}>Next<RightOutlined /></Button>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Tips
                        result={result}
                        next={this.getNextTopic}
                        tips={tips}
                        close={() => { this.setState({ tipsModalShow: false }) }} open={tipsModalShow} />
                </div>
            </>
        )
    }
}