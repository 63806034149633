import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SavePaperQuestion, SaveTopicList, GetAllQuestion, GetPaperApp, GetPaperQuestion } from '../../api/paper';
import { Modal, Form, Input, Space, Radio, Button, Switch, message, Transfer } from 'antd'
const AddPaperFormModal = ({ id, open, close, fetch }) => {
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [allList, setAllList] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (open) {
            const getPaperQuestion = async () => {
                const { data: res } = await GetPaperQuestion(id)
                if (res.isSuccess) {
                    let selectArray = res.data.map((item) => { return item.questionID })
                    setSelectedKeys(selectArray)
                }

            }
            const getQuestion = async () => {
                const { data: res } = await GetAllQuestion(id)
                if (res.isSuccess) {
                    setAllList(res.data)
                }
            }
            getQuestion()
            getPaperQuestion()
        }
    }, [id, open])
    const submit = async () => {
        setLoading(true)
        const { data: res } = await SavePaperQuestion(id, selectedKeys)
        if (res.isSuccess) {
            setLoading(false)
            closeModal()
            fetch()
            message.success(res.message)
        }
    }

    const closeModal = () => {
        setTargetKeys([])
        setSelectedKeys([])
        close()
    }
    const handleChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    }
    const handleFilter = (inputValue, option) => {
        let topic = option.topic.toLowerCase() ;
        let tags = option.tags;
        inputValue = inputValue.toLowerCase();
        return topic.indexOf(inputValue) !== -1 || tags.some(tag=>tag.trim().indexOf(inputValue)!== -1)
    }
    const handleSearch = (dir, value) => {
        //    console.log(dir,value)
    };
    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }
    return (
        <Modal confirmLoading={loading} width={'70vw'} height={'80vw'} title="Select Question" visible={open} onOk={submit} onCancel={closeModal}>
            <Transfer
                dataSource={allList}
                titles={['All Questions', 'Selected Questions']}
                targetKeys={selectedKeys}
                selectedKeys={selectedKeys}
                filterOption={handleFilter}
                onChange={handleChange}
                onSelectChange={handleSelectChange}
                onSearch={handleSearch}
                render={item => item.topic}
                rowKey={item => item.questionID}
                showSearch={true}
                listStyle={{
                    width: 400,
                    height: 500,
                }}

            />
        </Modal>
    )
}

AddPaperFormModal.propTypes = {

}

export default AddPaperFormModal
